import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'

const CookieBanner = (props) => {
  // const classNames = {
  //   [styles.cookiebanner]: true,
  //   [className]: className
  // }

  const gtm = 'UA-203068826-6';
  const [isSet, setAllowStatus] = useState('initialState');
  const id = 'gtm-tracking';

  function allowAnalytics() {
    const scriptGtag = document.createElement('script');
    const script = document.createElement('script');
    scriptGtag.src = `https://www.googletagmanager.com/gtag/js?id=${gtm}`;
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${gtm}');
    `;

    (document.getElementsByTagName( "body" )[ 0 ]).appendChild( scriptGtag );
    (document.getElementsByTagName( "body" )[ 0 ]).appendChild( script );
  }

  function allowCookies() {
    localStorage.setItem(`allowCookies-${gtm}`, true);
    allowAnalytics();
    setAllowStatus(true);
  }

  function disallowCookies() {
    localStorage.setItem(`allowCookies-${gtm}`, false);
    setAllowStatus(true);
  }

  useEffect(() => {
    const cookiesAllowed = localStorage.getItem(`allowCookies-${gtm}`);

    if (isSet === 'initialState') {
      setAllowStatus(cookiesAllowed);
      
      if (cookiesAllowed === 'true') {
        if (!document.getElementById(id)) {
          allowCookies();
        }
      } else if (cookiesAllowed === 'false') {
        disallowCookies();
      }
    } 
  }, []);

  // Elements

  // Render
  return (
    <>
      { isSet !== null ? 
        ''
        : (
          <div className={ styles.banner }>
            <div className={ styles.bannerInner }>
              <p>This website uses cookies to ensure you the best experience on our website.&nbsp;
                <a 
                  href="https://www.bbtv.com/privacy-policy" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </a>
              </p>
              <div className={ styles.buttons }>
                <button
                  className={ styles.declineBtn }
                  onClick={e => {
                    e.preventDefault();
                    disallowCookies();
                  }}
                >
                  Decline
                </button>

                <button
                  className={ styles.acceptBtn }
                  onClick={e => {
                    e.preventDefault();
                    allowCookies();
                  }}
                >
                  Accept
                </button>
              </div>
            </div>
          </div>
        )
      }
    </>
    
  )
}

CookieBanner.defaultProps = {}

export default CookieBanner

